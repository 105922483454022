import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Content from "../components/Content";
import Image from "../components/Image";
import showdown from "showdown";
import { graphql } from "gatsby";
import Footer from "../components/Footer";
import Heading from "../components/Heading";
import SideBar from "../components/SideBar";

const converter = new showdown.Converter();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
`;

const Description = styled.div`
  margin-top: 20px;
`;

const descr = `Somos una congregación de hermanos en Cristo provenientes de una de las iglesias evangélicas mas grandes de Chile naciente del avivamiento de 1909. El día 03 de agosto del año 2003 formamos una nueva congregación bajo la denominación:
"Iglesia Remanente Metodista Pentecostal".

Quienes están al frente de la misión y de la Congregación de la Iglesia Central, son sus Pastores Fundadores, el actual Obispo Presidente Rvdo. Daniel Rodríguez Vergara y nuestra Pastora Diaconisa María Verónica Escobar de Rodríguez. A la fecha tenemos 22 pastores asociados pertenecientes a nuestra misión a lo largo del país y en la ciudad de Santiago.

Nuestros cultos son celebrados en la forma tradicional Metodista Pentecostal, con un coro instrumental y un coro polifónico compuesto por miembros de nuestra congregación, siendo lo central de nuestra reunión la exhortación de la palabra de Dios.

Realizamos predicaciones por calles y plazas de nuestras comunas. Le invitamos afectuosamente a congregarse con nosotros para adorar a Dios.`;

export default class NuestraHistoriaPage extends Component {
  render() {
    const { data } = this.props;

    return (
      <Layout>
        <Seo title="Nuestra Historia" keywords={[`iglesia`]} />
        <Container>
          <Header />
          <Content>
            <Inner>
              <Grid container spacing={40}>
                <Grid item xs={12} md={8}>
                  <Heading>Nuestra Historia</Heading>
                  <Image fluid={data.profileImage.childImageSharp.fluid} />
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: converter.makeHtml(descr)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SideBar fixTop={180} fixBottom={140} about={false} />
                </Grid>
              </Grid>
            </Inner>
          </Content>
          <Footer />
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query NuestraHistoriaPageQuery {
    profileImage: file(relativePath: { eq: "DSC_5548.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
